
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { ISession } from "@/schemas/ISession";
import { ActivityType, Scope, SessionState, UnitType } from "@/schemas/Enums";
import ProgressTimer from "@/components/task/common/ProgressTimer.vue";
import DisplayEndDialog from "@/components/dialog/DisplayEndDialog.vue";
import TipsContainer from "@/components/tips/TipsContainer.vue";
import SlideGroupRankingList from "@/components/SlideGroupRankingList.vue";
import ChatRoom from "@/components/task/common/chat/ChatRoom.vue";
import LoadingMessageDialog from "@/components/dialog/LoadingMessageDialog.vue";
import { webSocketUrl } from "@/env";
import clientCache from "@/utils/cacheUtils";
import { ITip } from "@/schemas/ITip";
import { IActivity } from "@/schemas/IActivity";
import AgreementSubmitButton from "@/components/task/common/AgreementSubmitButton.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { ISetting } from "@/schemas/ISetting";
import WebSocketManager from "@/utils/websocketManager";

@Component({
  name: "GroupSession",
  computed: {
    ...mapGetters("session", [
      "getMessages",
      "getGroupSessionTime",
      "getActiveUsers",
    ]),
    ...mapState("session", ["session", "rankedItems"]),
    ...mapState("user", ["userId"]),
  },
  methods: {
    ...mapMutations({ setSnack: "SET_SNACK" }),
    ...mapActions("session", ["getUserWhoAgreedOnSessionEnd"]),
  },
  components: {
    AgreementSubmitButton,
    TipsContainer,
    DisplayEndDialog,
    SlideGroupRankingList,
    LoadingMessageDialog,
    ProgressTimer,
    ChatRoom,
  },
})
export default class GroupSession extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;

  isLoading = true;
  isFinished = false;
  remainingTime = NaN;

  heartbeatId = 0;
  lastHeartbeatId = 0;
  interval = null;

  session: ISession | undefined;
  setting: ISetting | undefined = undefined;
  sessionStatusTriggerId = null;
  canRequest = true;
  wait = 0;
  displayEnd = false;

  rankWS: WebSocket | null = null;
  rankWSRetries = 0;

  wsMaxRetries = 3;
  waitBeforeReconnect = 3000;

  displayHighlightNotification = false;

  created(): void {
    this.$store.dispatch("session/fetchSession", {
      sessionId: this.sessionId,
      next: (status: SessionState) => {
        if (status === SessionState.Finished) {
          this.isLoading &&= false;
          this.isFinished = true;
          this.displayEnd = true;
          return;
        }
        if (status === SessionState.Created) {
          this.$router.push({
            name: "singleSession",
            params: {
              sessionId: this.sessionId,
            },
          });
          return;
        }

        if (status === SessionState.RunningGroup) {
          // TODO : need to reconnect to group session
          this.$store.dispatch("session/connectToSession", {
            sessionId: this.sessionId,
            channel: "group",
          });
          // this.sessionStatusTriggerId = this.allConnectedHTTPTrigger();
          this.launchSession();
        }
        if (status === SessionState.RunningSingle) {
          // TODO : need to connect to group session
          // TODO: check if has submitted single session
          this.$store.dispatch("session/connectToSession", {
            sessionId: this.sessionId,
            channel: "group",
          });
          // this.sessionStatusTriggerId = this.allConnectedHTTPTrigger(1000);
          this.launchSession();
        }
      },
      handleFetchedSession: (session: ISession) => {
        this.$store.dispatch("setting/fetchSetting", {
          settingId: session.setting_id,
          next: (setting: ISetting) => {
            this.setting = setting;
          },
        });
      },
    });
  }

  timeOutHandler(): void {
    // La fonction simule le comportement de fin de session normale (accord des utilisateurs),
    // dans le cas où le temps imparti est écoulé.
    //  Elle termine ensuite la session.
    this.$refs.agreementButton.acceptGroupSessionEnd();
    this.endOfGroupSessionHandler();
  }

  get displayTips(): boolean {
    return !!this.setting?.group_tips_id;
  }
  get tips(): ITip[] {
    if (this.displayTips) {
      return this.setting?.group_tips.tips;
    } else {
      return [];
    }
  }
  get activity(): IActivity {
    return this.session?.activity || {};
  }
  get feedbackEnable(): boolean {
    return this.session
      ? this.session.setting
        ? this.session.setting.feedback
        : true
      : true;
  }
  get isSubmitDisable(): boolean {
    return !Object.values(this.rankedItems).every(
      (item) => Object.keys(item).length > 0
    );
  }
  get isCarousel(): {
    carousel_tips: boolean;
    carousel_tip_unit?: UnitType;
    carousel_display_time?: number;
    carousel_occurrence?: number;
  } {
    return this.setting
      ? {
          carousel_tips: this.setting?.carousel_tips,
          carousel_tip_unit: this.setting?.carousel_tip_unit,
          carousel_display_time: this.setting?.carousel_display_time || 1,
          carousel_occurrence: this.setting?.carousel_occurrence || 1,
        }
      : { carousel_tips: false };
  }
  get estimatedEnd(): number {
    if (this.session.estimated_group_end_time) {
      return new Date(this.session.estimated_group_end_time)
        .getTime()
        .toString();
    } else {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.beginning ??= new Date(Date.now());
      return this.beginning
        .setMinutes(
          this.beginning.getMinutes() +
            this.session.activity?.group_session_time
        )
        .toString();
    }
  }

  launchSession(): void {
    this.sessionStatusTriggerId = this.allConnectedHTTPTrigger();
    this.connectRankingWS();
  }

  connectRankingWS(): void {
    let rankingWSUrl = `${webSocketUrl}/move/${this.sessionId}/${this.userId}`;
    this.rankWS = new WebSocket(rankingWSUrl);

    if (this.isLoading) {
      this.rankWS.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.type === "heartbeat") {
          this.lastHeartbeatId = data.heartbeat_id;
        }
      };
    }

    this.rankWS.onopen = () => {
      console.log("Successfully connected to the ranking.");

      this.interval = setInterval(() => {
        if (this.heartbeatId - this.lastHeartbeatId > 3) {
          console.log("Closing the ranking WS due to inactivity...");
          this.rankWS?.close(1000, "No heartbeat sent in the last 3 seconds");
          //location.reload();
          this.setSnack({
            snackText:
              "La connexion WebSocket du classement a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
            snackColor: "error",
            snackClosable: true,
          });
          this.lastHeartbeatId = this.heartbeatId;
          window.clearInterval(this.interval)
          setTimeout(() => {
            console.log("Refreshing the ranking list...");
            this.$refs.groupRankingList.onRefresh()
          }, 1000);
        }

        this.rankWS?.send(JSON.stringify({
          type: "heartbeat",
          heartbeat_id: this.heartbeatId
        }))
        this.heartbeatId++;
      }, 1000);
    };
    this.rankWS.onclose = this.onRankingWSClose;
    this.rankWS.onerror = this.onRankingWSClose;
  }

  onRankingWSClose(event: CloseEvent): void {
    console.log(event);
    console.log(
      "WebSocket disconnected from the ranking endpoint",
      event.code,
      event.reason
    );
    if (event.code != 1000) {
      if (this.rankWSRetries < this.wsMaxRetries) {
        setTimeout(() => {
          console.log("Attempting to reconnect to the ranking WS...");
          this.connectRankingWS();
          this.rankWSRetries++;
        }, this.waitBeforeReconnect);
      } else {
        // Alert user and provide a user-friendly message
        this.setSnack({
          snackText:
            "La connexion WebSocket du classement a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
          snackColor: "error",
          snackClosable: true,
        });
      }
    }
  }


  redirect(): void {
    this.clearStorage();
    this.setUserSessions([]);
    this.cleanAndLeave();
    this.clearInterval();
    this.closeWS();
  }

  endOfGroupSessionHandler(): void {
    let result = {
      type: "ranking",
      scope: Scope.Group,
      session_id: this.sessionId,
      ranking_session_id: this.sessionId,
    };
    console.log("Ici quand on time out")
    this.$store
      .dispatch("session/submitSessionResult", {
        result,
        resultType: ActivityType.Ranking,
        next: () => {
          this.cleanAndLeave();
          this.displayEnd = true;
          this.clearStorage();
        },
      })
      .catch((error: Error) => {
        if (error.message === "TypeError: ranking is null") {
          this.setSnack({
            snackText: "Erreur lors de la soumission des résultats",
            snackColor: "error",
            snackClosable: true,
          });
        }
        console.log(error);
      });
  }

  cleanAndLeave(): void {
    this.closeWS();
    this.clearInterval();
    this.$store.dispatch("session/disconnectFromSession", {
      sessionId: this.sessionId,
      channel: "group",
    });
    const mainContainer = document.getElementById("main-container");
    if (mainContainer) mainContainer.style.backgroundImage = ``;
  }

  allConnectedHTTPTrigger(timeout: number): number {
    return window.setInterval(
      () => {
        if (this.canRequest || this.wait === 10) {
          this.wait = 0;
          this.canRequest = false;
          this.$store.dispatch("session/getSessionStatus", {
            sessionId: this.sessionId,
            next: (response) => {
              this.canRequest = true;
              this.sessionStatusHandler(response);
            },
            channel: "group",
          });
        } else {
          this.wait++;
        }
      },
      timeout ? timeout : 1500
    );
  }

  sessionStatusHandler(message: {
    status: SessionState;
    remaining_time?: number;
  }): void {
    let status = message.status;
    let remainingTime =
      message.remaining_time || message.remaining_time === 0
        ? message.remaining_time
        : NaN;
    if (status === SessionState.RunningGroup) {
      if (remainingTime > 0) {
        // set the remaining time
        this.remainingTime = remainingTime;
        // Fetch other results if not already loaded
        if (!this.fetchedGroup) {
          this.$store.dispatch("session/fetchSession", {
            sessionId: this.sessionId,
          });
          this.fetchedGroup = true;
        }
        // on group session starting clean the connection state request trigger
        if (this.sessionStatusTriggerId) {
          clearInterval(this.sessionStatusTriggerId);
          this.sessionStatusTriggerId = null;
        }
        // set a new trigger of 3 seconds instead of 1
        this.runningTriggerId ??= this.allConnectedHTTPTrigger(3500);

        if (!this.moveResumed) {
          this.$store.dispatch("session/getCachedRanking", this.sessionId);
          this.moveResumed = true;
        }
        this.isLoading &&= false;
      } else {
        this.isLoading &&= false;
        this.closeWS();
        this.clearInterval();
        this.displayEnd = true;
      }
      return;
    }
    if (status === SessionState.RunningSingle) {
      this.$store.dispatch("session/disconnectFromSession", {
        sessionId: this.sessionId,
        channel: "group",
      });
      this.$router.push({
        name: "singleSession",
        props: {
          sessionId: this.sessionId,
        },
      });
      return;
    }
    if (status === "wait_group") {
      if (this.remainingTime < 0) {
        this.displayEnd = true;
        this.closeWS();
        this.clearInterval();
      }
    }
  }

  clearStorage(): void {
    clientCache.clear("ranked_items");
    clientCache.clear("items_to_be_ranked");
    clientCache.clear(`${this.sessionId}:has_submitted_single`);
  }

  /**
   * See https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close for further informtion
   * Note that ff you specify a code value, you MUST also specify a reason value.
   */
  closeWS(): void {
    if (this.rankWS) {
      this.rankWS.close(
        1000,
        "Websocket connection normally closed by the app"
      );
    }
  }

  onHighlightChanged(event: { is_highlight: boolean; tip_id: string }): void {
    this.displayHighlightNotification = event.is_highlight;
    setTimeout(() => (this.displayHighlightNotification = false), 30000);
  }

  refreshHandler(handler: CallableFunction): void {
    this.rankWS.close(1000);
    console.log(this.rankingWS);
    this.rankWS = new WebSocket(
      `${webSocketUrl}/move/${this.sessionId}/${this.userId}`
    );
    this.rankWS.onmessage = handler
    this.rankWS.onopen = function () {
      console.log("Successfully reconnected to the ranking websocket.");
    };
    this.rankWS.onclose = (event) => {
      console.log("Ranking websocket closed");
      console.log("code");
      console.log(event.code);
      console.log("reason");
      console.log(event.reason);
      console.log("wasClean");
      console.log(event.wasClean);
    };

    const reconnectWS = () => {
      this.rankWS = new WebSocket(
        `${webSocketUrl}/move/${this.sessionId}/${this.userId}`
      );
    };
    this.rankWS.onerror = function (error) {
      console.log(`Error with ranking websocket : `, error.type);
      reconnectWS();
    };
    this.$store.dispatch("session/getCachedRanking", this.sessionId);
  }

  beforeDestroy(): void {
    this.closeWS();
    this.clearInterval();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.closeWS();
    this.clearInterval();
    next();
  }

  clearInterval(): void {
    if (this.interval) {
      window.clearInterval(this.interval)
    }

    if (this.sessionStatusTriggerId) {
      window.clearInterval(this.sessionStatusTriggerId);
    }
    if (this.runningTriggerId) {
      window.clearInterval(this.runningTriggerId);
    }
  }
}
